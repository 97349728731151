@import '~sanitize.css/sanitize.css';
@import '~tiny-slider/src/tiny-slider.scss';
@import '~glightbox/dist/css/glightbox.min.css';
@import './variables.scss';
@import './animations.scss';
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/bootstrap-utilities";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";

html {
    font-size: 62.5%;
}

body {
    background: map-get($colors, 'brand');
    color: map-get($colors, 'main');
    font: 1.8rem/1.5  map-get($fonts, 'main');
}

h2 {
    margin: 0 0 $offset;
    font-weight: normal;
    font-size: 3rem;
    color: map-get($colors, 'secondary');
}

h3 {
    margin: 0 0 $offset;
    font-weight: normal;
    font-size: 2.5rem;
}

p {
    margin: 0 0 $offset;
}

ul {
    margin: 0 0 $offset;
}

a {
    color: map-get($colors, 'secondary');
}

button {
    &:focus:not(:focus-visible) {
        outline: 0;
    }
}

img,
video {
    max-width: 100%;
    height: auto;
}

.site {
    overflow: hidden;
}

.container {
    position: relative;
}

.site-header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 100;
}

.site-nav {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 100;
}
.site-nav__btn {
    position: absolute;
    left: 15px;
    top: 15px;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    background: map-get($colors, 'brand-10');
    color: #fff;
    z-index: 2;
    opacity: 0;
    transition: .3s;
    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 25px;
        transform: translate(-50%, -50%);
    }
    &:before {
        height: 16px;
        border: 2px solid;
        border-left: none;
        border-right: none;
    }
    &:after {
        border-bottom: 2px solid;
    }
    .site-nav_active & {
        &:before {
            height: 0;
            border-bottom: none;
            transform: translate(-50%, -50%) rotate(-45deg) ;
        }
        &:after {
            transform: translate(-50%, -50%) rotate(45deg) ;
        }
    }
    .loaded & {
        animation-name: fadeInDown;
    }
}
.site-nav__list {
    position: absolute;
    left: 0;
    top: 0;
    width: 250px;
    height: 100%;
    margin: 0;
    padding: $offset * 2 0 0;
    background: map-get($colors, 'brand-10');
    list-style: none;
    color: #fff;
    font-size: 1.8rem;
    text-transform: uppercase;
    transition: .3s;
    transform: translateX(-100%);
    text-align: left;
    li {
        &:before {
            display: none;
        }
    }
    a {
        display: block;
        padding: $offset / 3 $offset;
        color: inherit;
        text-decoration: none;
        transition: .5s;
        &.site-nav__link_alt {
            background: rgba(map-get($colors, 'brand-14'), .9);
        }
    }
    .site-nav_active & {
        transform: translateX(0);
    }
}

.site-content {
    .container {
        padding-top: $offset;
        padding-bottom: $offset;
    }
}

.section {
    &__inner {
        position: relative;
        z-index: 10;
        &:before,
        &:after {
            display: none;
        }
    }
    .container {
        position: relative;
        z-index: 10;
    }
    &__video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(map-get($colors, 'brand-7'), .5);
        }
        video {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &_intro {
        background: map-get($colors, 'brand-7');
        color: #fff;
        .container {
            padding-top: 60px;
        }
    }
    &_militia {
        .section__inner {
            &:before {
                content: '';
                position: absolute;
                left: -240px;
                top: 0;
                width: 711px;
                height: 980px;
                background: url('../images/bg-photo-1.png') no-repeat 0 0;
                z-index: -10;
                opacity: 0;
            }
            &:after {
                content: '';
                position: absolute;
                right: -240px;
                top: calc(100% - 403px);
                width: 854px;
                height: 1213px;
                background: url('../images/bg-photo-2.png') no-repeat 0 0;
                z-index: -10;
                opacity: 0;
            }
        }
    }
    &_history {
        background: map-get($colors, 'brand-2');
        .section__inner {
            z-index: 15;
        }
    }
    &_family {
        background: map-get($colors, 'brand-3');
        .section__inner {
            &:before {
                content: '';
                position: absolute;
                left: -240px;
                bottom: 0;
                width: 1047px;
                height: 952px;
                background: url('../images/bg-photo-3.png') no-repeat 0 0;
                z-index: -10;
                opacity: 0;
            }
        }
    }
    &_film {
        background: map-get($colors, 'brand-4');
        .section__inner {
            overflow: hidden;
            &:before {
                content: '';
                position: absolute;
                right: -250px;
                top: -363px;
                width: 1410px;
                height: 1166px;
                background: url('../images/bg-photo-4.jpg') no-repeat 0 0;
                z-index: -10;
                opacity: 0;
            }
        }
    }
    &_form {
        overflow: hidden;
        background: map-get($colors, 'brand-5');
        .section__inner {
            &:before {
                content: '';
                position: absolute;
                left: -220px;
                top: 0;
                width: 1164px;
                height: 933px;
                background: url('../images/bg-photo-5.jpg') no-repeat 0 0;
                z-index: -10;
                opacity: 0;
            }
        }
    }
    &_stories {
        overflow: hidden;
        background: map-get($colors, 'brand-9');
        .section__inner {
            z-index: 15;
        }
    }
    &_news {
        background: map-get($colors, 'brand-6');
        .section__inner {
            &:before {
                content: '';
                position: absolute;
                right: 0;
                top: -205px;
                width: 809px;
                height: 1139px;
                background: url('../images/bg-photo-6.png') no-repeat 0 0;
                z-index: -10;
                opacity: 0;
            }
        }
    }
    &_film,
    &_form,
    &_news {
        color: map-get($colors, 'alt');
        h2 {
            color: inherit;
        }
    }
    &_militia.visible,
    &_family.visible,
    &_form.visible {
        .section__inner {
            &:before {
                animation: fadeInRight 1.5s $easing both;
            }
        }
    }
    &_militia.visible {
        .section__inner {
            &:after {
                animation: fadeInLeft 1.5s $easing both;
            }
        }
    }
    &_film.visible,
    &_news.visible {
        .section__inner {
            &:before {
                animation: fadeInLeft 1.5s $easing both;
            }
        }
    }
}

.site-footer {
    position: relative;
    background: map-get($colors, 'brand-7');
    color: map-get($colors, 'alt');
    z-index: 10;
    p {
        margin-bottom: $offset / 2;
        font-size: 70%;
    }
    a {
        color: inherit;
        text-decoration: underline;
    }
    .container {
        padding-top: $offset;
        padding-bottom: $offset;
    }
}

.btn {
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    background: map-get($colors, 'brand-5');
    border: none;
    border-radius: 0;
    font: 2.1rem map-get($fonts, 'main');
    text-decoration: none;
    color: #fff;
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
    transition: .5s;
    &:hover {
        background: map-get($colors, 'secondary');
    }
    &:focus:not(:focus-visible) {
        outline: 0;
    }
    &_secondary {
        background: map-get($colors, 'brand-8');
    }
    &_alt {
        background: map-get($colors, 'brand-13');
    }
    &_toggle {
        background: none;
        padding: 0 $offset 0 0;
        color: map-get($colors, 'secondary');
        text-transform: uppercase;
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            width: 20px;
            height: 12px;
            background: url('../images/icon-arrow-d.svg') no-repeat 50% 50%;
            transform: translateY(-50%);
        }
        &_active {
            &:after {
                background-image: url('../images/icon-arrow-t.svg');
            }   
        }
        &:hover {
            background: none;
            color: map-get($colors, 'brand-10');
        }
    }
    &[disabled] {
        opacity: .5;
        cursor: default;
        background: map-get($colors, 'brand-5') !important;
    }
}

.btn-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    padding: 0;
    background-color: map-get($colors, 'brand-10');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: none;
    cursor: pointer;
    &:focus:not(:focus-visible) {
        outline: none;
    }
    &_share {
        display: block;
        background-image: url('../images/icon-share.svg');
        background-size: 50% auto;
    }
}

.btn-m24 {
    display: block;
    overflow: hidden;
    width: 168px;
    height: 24px;
    background: url('../images/logo-m24.svg') no-repeat 0 0;
    white-space: nowrap;
    text-indent: -999px;
    .site-header & {
        display: none;
        opacity: 0;
    }
    .loaded & {
        animation-name: fadeInDown;
    }
}

.btn-mosru {
    display: block;
    overflow: hidden;
    width: 240px;
    height: 80px;
    background: url('../images/logo-mosru.svg') no-repeat 0 0;
    white-space: nowrap;
    text-indent: -999px;
}

.form-group {
    margin: 0 0 $offset;
    &:last-child {
        margin: 0;
    }
}
.form-control {
    width: 100%;
    padding: 5px 0;
    background: none;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    font: 1.8rem map-get($fonts, 'main');
    color: map-get($colors, 'main');
    &:focus:not(:focus-visible) {
        outline: none;
    }
    .validated &:invalid {
        color: map-get($colors, 'brand-10');
    }
}
.form-upload {
    position: relative;
    width: 100%;
    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }
    .loader {
        display: none !important;
    }
    .btn {
        width: 100%;
    }
}
.form-checkbox {
    display: inline-block;
    width: 26px;
    height: 26px;
    margin: 0 10px 0 0;
    border: 2px solid currentColor;
    border-radius: none;
    vertical-align: top;
    cursor: pointer;
    appearance: none;
    color-adjust: exact;
    &:checked {
        background: linear-gradient(map-get($colors, 'main'), map-get($colors, 'main')) no-repeat 50% 50% / 60% 60%;
    }
    .validated &:invalid {
        border-color: map-get($colors, 'brand-10');
    }
}

.form_request {
    max-width: 770px;
    margin: auto;
    padding: $offset;
    background: map-get($colors, 'alt');
    color: map-get($colors, 'main');
}

.message {
    padding: 5px 0;
    text-align: center;
    &_error {
        background: map-get($colors, 'error');
        color: map-get($colors, 'alt');
    }
    &_success {
        background: map-get($colors, 'success');
        color: map-get($colors, 'alt');
    }
}

.thumbs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: $offset 0 0;
    &__item {
        position: relative;
        overflow: hidden;
        width: 100px;
        height: 100px;
        margin: 0 10px 10px 0;
        box-shadow: 0 2px 10px rgba(0, 0, 0, .20);
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.loader {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 0 auto $offset;
    font-size: 10px;
    text-indent: -9999em;
    box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    color: #fff;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 5px solid transparent;
        border-top: 5px solid currentColor;
        border-radius: 50%;
        transform: translateZ(0);
        animation: load8 1.1s infinite linear;
        box-sizing: border-box;
    }
    .btn & {
        display: none;
        width: 20px;
        height: 20px;
        margin: 0 5px 0 0;
        box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.1);
        &:after {
            border-width: 2px;
        }
    }
    .btn.loading & {
        display: inline-block;
    }
}

.heading {
    margin: 0 auto $offset * 1.5;
    text-align: center;
    h2 {
        line-height: 1;
    }
    p:last-child {
        margin: 0;
    }
}

.logo {
    width: 300px;
    margin: 0 auto $offset / 2;
    &__img {
        width: 100%;
        height: 132px;
        margin: 0 0 $offset / 2;
        background: url('../images/logo.png') no-repeat 0 0 / contain;
        opacity: 0;
    }
    &__text {
        width: 100%;
        padding: 5px 0;
        background: map-get($colors, 'brand-9');
        color: map-get($colors, 'main');
        font-size: 2.1rem;
        text-transform: uppercase;
        text-align: center;
        opacity: 0;
    }
    .loaded & {
        &__img,
        &__text {
            animation-name: fadeInUp;
        }   
    }
}

.stats {
    &__item {
        margin: 0 0 $offset / 2;
        text-align: center;
        opacity: 0;
        &:last-child {
            margin: 0;
        }
    }
    &__value {
        font: 5rem/1 map-get($fonts, 'secondary');
        text-shadow: 3px 3px 7px rgba(0,0,0, 0.8);
    }
    &__desc {
        font-size: 1.5rem;
        text-shadow: 4px 4px 10px #000;
    }
    .loaded & {
        &__item {
            animation-name: fadeInUp;
        }
    }
}

.video {
    position: relative;
    overflow: hidden;
    margin: auto;
    a {
        position: relative;
        display: block;
        background: map-get($colors, 'brand-11');
        color: map-get($colors, 'alt');
        text-decoration: none;
    }
    img {
        width: 100%;
        transform: scale(1);
        transition: .5s;
    }
    &__play {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 70px;
        height: 70px;
        background: rgba(255, 255, 255, .5);
        color: map-get($colors, 'main');
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: .5s;
        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            border-left: 25px solid;
            border-top: 17px solid transparent;
            border-bottom: 17px solid transparent;
            transform: translate(-9px, -50%);
        }
    }
    &__title {
        position: relative;
        padding: 10px;
        text-align: center;
        background: map-get($colors, 'brand-11');
    }
    .videos & {
        margin-bottom: $offset;
        box-shadow: 4px 4px 32px rgba(0, 0, 0, .44);
        &__play {
            left: 16px;
            top: 16px;
            transform: none;
        }
    }
    .section_form & {
        max-width: 370px;
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
        .video__play {
            background: map-get($colors, 'brand-10');
        }   
    }
}

.story {
    position: relative;
    figure {
        width: 215px;
        margin: 0 auto $offset / 2;
    }
    h3 {
        margin-bottom: $offset / 2;
        text-align: center;
    }
}

.news {
    margin: 0 0 $offset;
    &__item {
        margin: 0 auto $offset;
    }
    a {
        position: relative;
        overflow: hidden;
        display: block;
        height: 295px;
        color: map-get($colors, 'alt');
        text-decoration: none;
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .5s;
    }
    h3 {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        margin: 0;
        padding: $offset / 2 $offset;
        background: rgba(map-get($colors, 'brand-12'), .9);
        font-size: 1.5rem;
    }
}

.partners {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.b-material-incut {
    a {
        display: block;
    }
    img {
        width: 100%;
    }
    .desc {
        padding: 5px 0 0;
        opacity: .5;
    }
}

.logos {
    display: flex;
    justify-content: space-between;
    margin: 0 0 $offset;
}

.carousel__item {
    display: inline-block;
}

.tns-outer {
    position: relative;
    margin: 0 0 $offset;
    .section_family & {
        margin: 0;
    }
    .section_film & {
        box-shadow: 4px 4px 32px rgba(0, 0, 0, .44);
    }
}
.tns-controls {
    button {
        position: absolute;
        top: 50%;
        overflow: hidden;
        width: 40px;
        height: 40px;
        background-color: map-get($colors, 'alt');
        background-repeat: no-repeat;
        background-size: 14px 24px;
        border: none;
        border-radius: 50%;
        white-space: nowrap;
        text-indent: -999px;
        transform: translateY(-50%);
        z-index: 100;
    }
    [data-controls="prev"] {
        left: 10px;
        background-image: url('../images/icon-arrow-l.svg');
        background-position: 45% 50%;
    }
    [data-controls="next"] {
        right: 10px;
        background-image: url('../images/icon-arrow-r.svg');
        background-position: 55% 50%;
    }
    .section_militia & {
        [data-controls="prev"] {
            left: 10px;
        }
        [data-controls="next"] {
            right: 10px;
        }   
    }
    .section_film & {
        button {
            background-color: transparent;
            background-size: 25px 40px;
        }
        [data-controls="prev"] {
            left: 0;
            background-image: url('../images/icon-arrow-l-2.svg');
        }
        [data-controls="next"] {
            right: 0;
            background-image: url('../images/icon-arrow-r-2.svg');
        }   
    }
    .section_stories & {
        button {
            top: 150px;
            background-color: rgba(map-get($colors, 'main'), .7);
            background-size: 13px 21px;
        }
        [data-controls="prev"] {
            left: 0;
            background-image: url('../images/icon-arrow-l-2.svg');
        }
        [data-controls="next"] {
            right: 0;
            background-image: url('../images/icon-arrow-r-2.svg');
        }
    }
}

.share {
    .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item {
        margin: 0 32px 0 0;
    }
    .ya-share2__list.ya-share2__list_direction_vertical > .ya-share2__item {
        margin: 10px 0 !important;
    }
    .ya-share2__badge {
        border-radius: 50%;
    }
    .ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
        height: 40px;
        width: 40px;
        background-repeat: no-repeat;
        background-size: 35px 35px;
        background-position: 50% 50%;
    }
    .ya-share2__title {
        display: none;
    }
    .share-toggle & {
        position: absolute;
        left: 0;
        top: 40px;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-10px);
        transition: .3s;
    }
    .share-toggle_active & {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}

.share-toggle {
    position: fixed;
    right: 15px;
    top: 15px;
    transition: backgound-color .3s $easing;
    z-index: 120;
    opacity: 0;
    .loaded & {
        animation-name: fadeInDown;
    }
}

.b-counters {
    overflow: hidden;
    height: 1px;
}

@media (min-width: map-get($breakpoints, 'md')) {
    .section {
        &_intro {
            .container {
                padding-top: 100px;
            }
        }
    }

    .form-upload {
        width: auto;
    }

    .stats {
        display: flex;
        justify-content: center;
        &__item {
            width: 170px;
        }
    }

    .videos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: -$offset / 2;
        margin-right: -$offset / 2;
        &__item {
            width: 50%;
            padding: 0 $offset / 2;
        }
    }

    .partners {
        &__item {
            padding: 0 $offset;
        }
    }
}

@media (min-width: map-get($breakpoints, 'lg')) {
    .site-header {
        padding: 20px 0 0;
        height: 80px;
        &__inner {
            position: relative;
            left: 0;
            top: 0;
            width: 100%;
            transition: .3s;
        }
        &.sticky {
            .site-header__inner {
                position: fixed;
                background: map-get($colors, 'brand-4');
                box-shadow: 0 8px 30px rgba(0, 0, 0, .2);
                color: map-get($colors, 'alt');
            }
        }
    }

    .site-nav {
        position: relative;
        height: auto;
        width: 100%;
        height: 60px;
    }
    .site-nav__btn {
        display: none;
    }
    .site-nav__list {
        position: relative;
        width: 100%;
        padding: 0 65px 0 0;
        background: none;
        transform: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        transition: none;
        font-size: 18px;
        text-align: center;
        opacity: 0;
        li {
            display: flex;
            align-items: center;
            height: 60px;
            padding: 0 20px;
        }
        a {
            height: 40px;
            padding: 0;
            line-height: 40px;
            letter-spacing: 1px;
            text-shadow: 0 0 10px rgba(0, 0, 0, .54);
            &.site-nav__link_alt {
                padding: 0 22px;
            }
            &:hover {
                color: map-get($colors, 'brand-10');
            }
        }
        .loaded & {
            animation-name: fadeInDown;
        }
    }

    .btn-m24 {
        .site-header & {
            position: absolute;
            left: 7.5px;
            top: 50%;
            display: block;
            width: 150px;
            height: 20px;
            margin-top: -10px;
            z-index: 110;
        }
    }

    .form_request {
        padding: $offset * 2 100px;
    }

    .logo {
        width: auto;
        margin: 0 0 $offset;
        &__img {
            width: 618px;
            height: 271px;
        }
        &__text {
            width: 475px;
            font-size: 2.3rem;
        }
    }

    .stats {
        justify-content: flex-start;
        &__item {
            margin: 0 $offset/2 0 0;
        }
        &__value {
            font-size: 7.3rem;
        }
        &__desc {
            font-size: 2.1rem;
        }
    }

    .heading {
        .section_militia & {
            width: 534px;
        }
        .section_history & {
            width: 600px;
        }
        .section_family &,
        .section_film &,
        .section_form & {
            width: 770px;
        }
    }

    .story {
        display: flex;
        padding: 0 60px;
        figure {
            width: 370px;
            margin: 0;
            padding: 10px 0 0 10px;
        }
        img {
            width: 100%;
            box-shadow: -10px -10px 0 0 map-get($colors, 'brand-15');
        }
        h3 {
            text-align: left;
        }
        &__inner {
            width: calc(100% - 370px);
            padding: 0 0 0 40px;
        }
    }

    .news {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &__item {
            width: 370px;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .tns-outer {
        .section_film & {
            margin-left: 100px;
            margin-right: 100px;
        }
    }

    .tns-controls {
        button {
            width: 60px;
            height: 60px;
            background-size: 20px 34px;
        }
        .section_film & {
            [data-controls="prev"] {
                left: -100px;
            }
            [data-controls="next"] {
                right: -100px;
            }   
        }
        .section_stories & {
            button {
                top: 50%;
                background-size: 20px 34px;
            }
            [data-controls="prev"] {
                left: -60px;
            }
            [data-controls="next"] {
                right: -60px;
            }
        }
    }

    .share-toggle {
        position: absolute;
        right: 7.5px;
        top: 50%;
        margin-top: -20px;
    }
}

@media (min-width: map-get($breakpoints, 'xl')) {
    .tns-controls {
        .section_militia &,
        .section_family & {
            [data-controls="prev"] {
                left: -90px;
            }
            [data-controls="next"] {
                right: -90px;
            }   
        }
    }
}

@media (min-width: map-get($breakpoints, 'xxl')) {
    h2 {
        font-size: 5rem;
    }

    .container {
        padding-left: 75px;
        padding-right: 75px;
    }

    .site-content {
        .container {
            padding-top: $offset * 2;
            padding-bottom: $offset * 2;
        }
    }

    .section {
        &__inner {
            &:before,
            &:after {
                display: block;
            }
        }
        &_intro {
            .container {
                padding-top: 100px;
                padding-bottom: 100px;
            }
        }
    }

    .site-footer {
        .container {
            padding-top: $offset * 2;
            padding-bottom: $offset * 2;
        }
    }

    .btn-m24 {
        .site-header & {
            left: 75px;
        }
    }

    .share-toggle {
        right: 75px;
    }
}

@media (min-width: map-get($breakpoints, 'xxxl')) {
    .section {
        &_militia {
            .section__inner {
                &:before {
                    left: 0;
                }
                &:after {
                    right: 0;
                }
            }
        }
        &_family,
        &_form {
            .section__inner {
                &:before {
                    left: 0;
                }
            }
        }
        &_film,
        &_news {
            .section__inner {
                &:before {
                    right: 0;
                }
            }
        }
    }   
}