@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.fadeIn {
    animation-name: fadeIn;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate(0, 30px, 0);
    }
    to {
        opacity: 1;
        transform: translate(0, 0, 0);
    }
}
.fadeInUp {
    animation-name: fadeInUp;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -30px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
.fadeInDown {
    animation-name: fadeInDown;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
.fadeInRight {
    animation-name: fadeInRight;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
.fadeInLeft {
    animation-name: fadeInLeft;
}

@for $i from 1 through 9 {
    .delay-#{$i + '00ms'} {
        animation-delay: #{'0.' + $i + 's'};
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}