$breakpoints: (
    "sm": 576px,
    "md": 768px,
    "lg": 992px,
    "xl": 1200px,
    "xxl": 1400px,
    "xxxl": 1920px
);

$fonts: (
    "main": #{'Oswald', sans-serif},
    "secondary": #{'Roboto Condensed', sans-serif}
);

$colors: (
    "main": #1F1F1F,
    "secondary": #00416B,
    "alt": #E7E2E2,
    "brand": #ECEAEA,
    "brand-2": #BCC5CD,
    "brand-3": #9CA6B0,
    "brand-4": #303D4C,
    "brand-5": #0F1E2F,
    "brand-6": #071320,
    "brand-7": #020A16,
    "brand-8": #4F7298,
    "brand-9": #F6F3F1,
    "brand-10": #EA1C25,
    "brand-11": #1F1E1E,
    "brand-12": #082E4E,
    "brand-13": #303D4C,
    "brand-14": #383226,
    "brand-15": #7E8C97,
    "success": #197854,
    "error": #dc3545
);

$offset: 30px;
$easing: cubic-bezier(0.215, 0.61, 0.355, 1);